<template>
  <div class="dashboard">
    <va-card :title="$t('emAtraso.title') + ` - ${filteredData.length} operações encontradas`">
      <div class="row align--center mb-1">
        <div class="flex xs12 sm6">
          <va-input
            class="ma-0"
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            :options="perPageOptions"
            v-model="perPage"
            label="Itens por página"
          />
        </div>
        <va-radio-button
          v-model="filtroAcordo"
          option="todos"
          label="Todos"
        />
        <va-radio-button
          v-model="filtroAcordo"
          option="quemTem"
          label="Apenas quem tem acordo"
        />

        <va-radio-button
          v-model="filtroAcordo"
          option="quemNaoTem"
          label="Apenas quem não tem acordo"
        />

      </div>

      <va-data-table
        :per-page="perPage"
        :fields="mode ? detailedFields : fields"
        :data="filteredData"
        :loading="loading"
        hoverable
      >

        <template slot="razaoSocial" slot-scope="props">
          <a :href="`https://zxvf.tutudigital.com.br/tomadores/${props.rowData.tomadorId}/dossie1`" target="_blank">
            {{props.rowData.razaoSocial}}
          </a>
        </template>

        <template slot="dataUltimaParcelaPaga" slot-scope="props">
          {{new Date(props.rowData.dataUltimaParcelaPaga).toLocaleString().substr(0,10)}}
        </template>

      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import operacoesService from '../../services/Cobranca/OperacoesService'

export default {
  components: {
  },
  data () {
    return {
      perPage: 15,
      perPageOptions: ['15', '25', '35', '50', '75', '100', '200', '300', '400', '500'],
      operacoes: [],
      apenasQuemNuncaFezAcordo: false,
      filtroAcordo: 'todos',
      filtroCobExterno: 'todos',
      loading: false,
      term: null,
      mode: 0,
      ajuizamento: {
        add: false,
        emprestimoId: null,
        razaoSocial: null,
        numeroProcesso: null,
        link: null,
      },
    }
  },
  computed: {
    fields () {
      return [{
        name: 'id',
        title: 'EmprestimoId',
        dataClass: 'text-center',
      }, {
        name: '__slot:razaoSocial',
        title: 'Razão Social',
      }, {
        name: 'acordoId',
        title: 'AcordoId',
        dataClass: 'text-center',
      },
      {
        name: 'prazo',
        title: 'Prazo',
        dataClass: 'text-center',
      },
      {
        name: 'diasAtraso',
        title: 'Dias em Atraso',
        dataClass: 'text-center',
      },
      {
        name: 'parcelasVencidas',
        title: 'Parcelas Vencidas',
        dataClass: 'text-center',
      },
      {
        name: '__slot:dataUltimaParcelaPaga',
        title: 'Última Parcela Paga',
      },
      {
        name: 'negativado',
        title: 'CNPJ Neg.',
      },
      {
        name: 'todosAvalistasNegativados',
        title: 'Todos Avalistas Neg.',
      },
      {
        name: 'algumAvalistaNegativado',
        title: 'Algum Avalista Neg.',
      },
      {
        name: 'estaEmCobrancaExternaGlobal',
        title: 'Cob. Externa',
      },
      {
        name: 'enviadoParaJuridico',
        title: 'Enviado para JURÍDICO',
      },
      {
        name: 'numeroProcesso',
        title: 'Número Processo',
      }]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }]
    },
    filteredData () {
      let ret = null
      if (!this.term || this.term.length < 1) {
        ret = this.operacoes
      } else {
        ret = this.operacoes.filter(item => {
          return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
        })
      }

      if (this.filtroAcordo === 'quemTem') {
        ret = ret.filter(x => x.acordoId != null)
      } else if (this.filtroAcordo === 'quemNaoTem') {
        ret = ret.filter(x => x.acordoId == null)
      }

      if (this.filtroCobExterno === 'quemTem') {
        ret = ret.filter(x => x.estaEmCobrancaExternaGlobal === 'Sim')
      } else if (this.filtroCobExterno === 'quemNaoTem') {
        ret = ret.filter(x => x.estaEmCobrancaExternaGlobal === 'Noã')
      }

      return ret
    },
  },
  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  watch: {
    'filtroAcordo' () {
      if (this.filtroAcordo === 'todos') {
        this.filtroCobExterno = 'todos'
      }
    },
  },
  async mounted () {
    this.operacoes = (await operacoesService.operacoesAtraso()).data.data
  },
}
</script>
